
import {
    defineComponent, PropType, ref, Ref
} from 'vue';
import { country, account as accountRequest } from '@/data/';
import HttpRequest from '@/util/axios.config';
import { setting as postalCodeRule } from '@/methods/rule';

interface FormData {
    ID: number;
    BillingTitle: string;
    Contactor: string;
    Street: string;
    City: string;
    Postcode: string;
    Country: string;
    TelePhone: string;
    Fax: string;
    Email: string;
}
type UserType = 'propertyCommunity' | 'office' | 'installer' | 'community' | 'distributorOffice' | 'distributorIns' | 'distributorCom';
export default defineComponent({
    props: {
        userType: {
            type: String as PropType<UserType>
        }
    },
    setup(props, { emit }) {
        const form: Ref<any> = ref(null);

        const getFormData = () => {
            const formData: Ref<FormData> = ref({
                ID: 0,
                BillingTitle: '',
                Contactor: '',
                Street: '',
                City: '',
                Postcode: '',
                Country: '',
                TelePhone: '',
                Fax: '',
                Email: ''
            });

            switch (props.userType) {
                case 'distributorOffice':
                case 'distributorIns':
                case 'distributorCom':
                    accountRequest.getDistributorBillingInfo((data: FormData) => { formData.value = data; });
                    break;
                default:
                    accountRequest.getInstallerBillingInfo((data: FormData) => { formData.value = data; });
                    break;
            }
            return formData;
        };
        const formData = getFormData();

        // 手动控制街道input栏底部的空白区域动态变化
        const limitBottom = ref(true);
        const streetBlur = () => {
            if (formData.value.Street) {
                limitBottom.value = true;
            } else {
                limitBottom.value = false;
            }
        };

        // 控制postCode样式问题
        const postClass = ref('');
        const postcodeBlur = () => {
            if (formData.value.Postcode !== '' && formData.value.Postcode.replace(/\s*/g, '') === '') {
                postClass.value = 'post-code';
            } else {
                postClass.value = '';
            }
        };

        // 提交检查必填选项
        const submit = () => {
            streetBlur();
            postcodeBlur();
            form.value.validate((valid: boolean) => {
                if (!valid) return false;
                let url = 'v3/web/common/manage/editBillingInfo';
                if (props.userType === 'distributorOffice' || props.userType === 'distributorIns' || props.userType === 'distributorCom') {
                    url = 'v3/web/common/manage/editBillingInfo';
                }
                HttpRequest.post(url, formData.value, () => {
                    emit('close');
                    emit('success');
                });
                return true;
            });
        };

        const rules = {
            BillingTitle: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelBillBillingTitle), trigger: 'blur' }
            ],
            Contactor: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelBillATTN), trigger: 'blur' }
            ],
            Street: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelCommunitiesStreet), trigger: 'blur' }
            ],
            City: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelCommunitiesCity), trigger: 'blur' }
            ],
            Postcode: [
                {
                    validator: postalCodeRule.onlyNumberAndNotEmpty,
                    trigger: 'blur'
                }
            ],
            TelePhone: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelBillTEL), trigger: 'blur' }
            ],
            Email: [
                { required: true, message: WordList.RuleEmailEmpty, trigger: 'blur' }
            ]
        };

        const countryOption = addressOption;
        return {
            formData,
            submit,
            rules,
            streetBlur,
            limitBottom,
            form,
            postcodeBlur,
            postClass,
            countryOption
        };
    }
});
