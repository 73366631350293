
import { defineComponent, ref, Ref } from 'vue';
import secondaryNav from '@/components/common/secondary-nav/index.vue';
import { order } from '@/data';
import { list } from '@/components/common/list';
import {
    Status, secNavs, CouponResItem,
    CouponInfo, FeeType, headers
} from './util';

export default defineComponent({
    components: {
        secondaryNav,
        list
    },
    setup() {
        const secActive: Ref<Status> = ref('Normal');

        const updateList = ref(0);
        const totalBalance = ref('0');
        const initData: Ref< Array<object> > = ref([]);

        // 获取云代金券列表信息
        const getCouponInfo = () => {
            order.getCouponInfo((res: CouponResItem) => {
                const couponItem: Array<object> = [];
                totalBalance.value = '0';
                res[secActive.value].forEach((item: CouponInfo) => {
                    const couponInfo = {
                        Number: item.Number,
                        Type: FeeType[item.Type],
                        Amount: `${item.UsedCount}/${item.TotalCount}`,
                        Time: `${item.StartTime}/${item.StopTime}`
                    };
                    couponItem.push(couponInfo);
                    totalBalance.value = item.TotalBalance;
                });
                initData.value = couponItem;
                updateList.value += 1;
            });
        };
        getCouponInfo();

        // 切换导航Normal、Used、Expired
        const chooseSecNav = (key: Status) => {
            secActive.value = key;
            getCouponInfo();
        };
        return {
            secNavs,
            secActive,
            chooseSecNav,
            headers,
            initData,
            updateList,
            getCouponInfo,
            totalBalance
        };
    }
});
