
import { defineComponent, ref, watch } from 'vue';

export default defineComponent({
    props: {
        name: {
            type: String,
            required: true
        },
        placement: {
            type: String,
            default: 'bottom'
        },
        isShowIcon: {
            type: Boolean,
            default: true
        },
        isShow: {
            type: Boolean,
            default: true
        }
    },
    setup(props, { emit }) {
        const showPop = ref(false);
        watch(() => props.isShow, () => {
            showPop.value = props.isShow;
        });

        document.body.addEventListener('click', () => {
            showPop.value = false;
            emit('change', showPop.value);
        }, false);

        const changePop = () => {
            showPop.value = !showPop.value;
            emit('change', showPop.value);
        };

        return {
            showPop,
            changePop
        };
    }
});
