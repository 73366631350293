
import {
    defineComponent, PropType, computed, ref
} from 'vue';
import { useRoute } from 'vue-router';

interface SubItem {
    label: string;
    icon: string;
}
interface MenuItem {
    name: string;
    label: string;
    icon?: string;
    path: string;
}

export default defineComponent({
    props: {
        submain: {
            type: Object as PropType<SubItem>,
            required: true
        },
        submenus: {
            type: Array as PropType<Array<MenuItem>>,
            required: true
        }
    },
    setup(props, { emit }) {
        const changeRouter = (name: string, path: string) => {
            if (name === 'document') {
                emit('showDocumentDialog', true);
            } else {
                window.open(path);
            }
        };
        const activeName = computed(() => (useRoute().matched[1] ? useRoute().matched[1].name : ''));
        const isShowSubMenu = ref(false);
        const ShowSubMenu = () => {
            isShowSubMenu.value = !isShowSubMenu.value;
        };
        return {
            changeRouter,
            activeName,
            isShowSubMenu,
            ShowSubMenu
        };
    }
});
