import { toDisplayString as _toDisplayString, createVNode as _createVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "margin-left20px" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_secondary_nav = _resolveComponent("secondary-nav")
  const _component_list = _resolveComponent("list")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.$wordList.ProperAllTextCouponDetails,
    footerType: "customize",
    width: "900px"
  }, {
    default: _withCtx(() => [
      _createVNode("label", null, _toDisplayString(_ctx.$wordList.ProperAllTextCouponBalance), 1),
      _createVNode("label", _hoisted_1, "$" + _toDisplayString(_ctx.totalBalance), 1),
      _createVNode(_component_secondary_nav, {
        class: "margin-top20px",
        navs: _ctx.secNavs,
        active: _ctx.secActive,
        onChoose: _ctx.chooseSecNav
      }, null, 8, ["navs", "active", "onChoose"]),
      _createVNode(_component_list, {
        showCheck: "false",
        headers: _ctx.headers,
        action: false,
        listData: _ctx.initData,
        update: _ctx.updateList,
        class: "max-height300px overflow-auto"
      }, null, 8, ["headers", "listData", "update"])
    ]),
    _: 1
  }, 8, ["title"]))
}