import { GetRequest, PostRequest } from '@/api/base';

interface DeleteInstaller {
    ID: string;
}
export function deleteInstaller(params: DeleteInstaller, callback: Function) {
    PostRequest('v3/web/common/installer/delete', params, callback);
}

export interface GetInitData {
    IsShowPermissionGranted: number;
    IsPwdFreeLogin: number;
    IsPwdFreeLoginNotice: number;
}
export function getInitData(params: object, callback: Function) {
    GetRequest('v3/web/common/installer/getInitData', params, callback);
}
