
import {
    defineComponent, ref
} from 'vue';
import dialogShell from '@/components/common/dialog-shell/index.vue';
import HttpRequest from '@/util/axios.config';
import { timeZone } from '@/data';
import Notice from '@/util/notice.base';

export default defineComponent({
    components: {
        dialogShell
    },
    props: {
    },
    setup(props, { emit }) {
        const time = (localStorage.getItem('manageTimeZone') === 'null' || localStorage.getItem('manageTimeZone') === null
            ? timeZone.defaultTimeValue : localStorage.getItem('manageTimeZone'));

        const timeOption = ref(time);

        const close = () => {
            emit('close');
        };

        const submit = () => {
            HttpRequest.post('v3/web/common/manage/editTimeZone', { TimeZone: timeOption.value }, (res: any) => {
                Notice.message(res.msg);
                localStorage.setItem('manageTimeZone', timeOption.value!);
                close();
            });
        };

        return {
            submit,
            close,
            time,
            timeZone,
            timeOption
        };
    }
});
