
import {
    defineComponent, PropType, reactive
} from 'vue';
import { editPwdFreeLoginForIns } from '@/api/common/pwdFreeLogin';

interface FormData {
    IsPwdFreeLogin: number;
}
export default defineComponent({
    props: {
        initForm: {
            type: Object as PropType<FormData>,
            required: true
        }
    },
    emits: ['close', 'success'],
    setup(props, { emit }) {
        const formData = reactive({ ...props.initForm });
        function submit() {
            editPwdFreeLoginForIns({ ...formData }, () => {
                emit('success');
                emit('close');
            });
        }
        return {
            formData,
            submit
        };
    }
});
