import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_input = _resolveComponent("el-input")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.$wordList.ProperAllTextCustomerService,
    onClose: _cache[3] || (_cache[3] = ($event: any) => (_ctx.$emit('close'))),
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode(_component_el_form, {
        "label-position": _ctx.$formLabelPosition,
        ref: "form",
        model: _ctx.formData,
        rules: _ctx.rules
      }, {
        default: _withCtx(() => [
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.TabelPersonUserInHtmlPhone,
            prop: "Phone"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.Phone,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.formData.Phone = $event)),
                placeholder: _ctx.$wordList.PropertyAllTextResidentsContactPhone
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"]),
          _createVNode(_component_el_form_item, {
            label: _ctx.$wordList.TabelPropertyManageEmail,
            prop: "Email"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_el_input, {
                modelValue: _ctx.formData.Email,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.formData.Email = $event)),
                placeholder: _ctx.$wordList.PropertyAllTextResidentsContactEmail
              }, null, 8, ["modelValue", "placeholder"])
            ]),
            _: 1
          }, 8, ["label"])
        ]),
        _: 1
      }, 8, ["label-position", "model", "rules"])
    ]),
    _: 1
  }, 8, ["title", "onSubmit"]))
}