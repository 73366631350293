import { GetRequest, PostRequest, successParam } from '@/api/base';

interface GetPwdFreeLoginToken {
    ID: string;
}
// dis获取临时token
export function getPwdFreeLoginToken(params: GetPwdFreeLoginToken, callback: Function) {
    GetRequest('v3/web/common/pwdFreeLogin/getPwdFreeLoginToken', params, callback);
}

interface PwdFreeLogin {
    TmpToken: string;
}
// dis通过临时token登录ins
export function pwdFreeLogin(params: PwdFreeLogin, callback: successParam) {
    PostRequest('v3/web/common/pwdFreeLogin/pwdFreeLogin', params, callback);
}

interface EditPwdFreeLoginForIns {
    IsPwdFreeLogin: number;
}
// ins编辑免密开关
export function editPwdFreeLoginForIns(params: EditPwdFreeLoginForIns, callback: Function) {
    PostRequest('v3/web/common/pwdFreeLogin/editPwdFreeLoginForIns', params, callback);
}