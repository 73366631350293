import { ref, Ref } from 'vue';
import { SelectItem } from '@/components/common/select-list';
import HttpRequest from '@/util/axios.config';
import { office, community } from '@/data';
import { isOffice } from '@/methods/role';
import router, {
    communityBaseRouter,
    communitySubRouter,
    installerBaseRouter, installerBatchRouter,
    installerSubRouter
} from '@/router';
import { ChangeWordList } from '@/methods/setText';
import communityApi from '@/api/community/community';
import Notice from '@/util/notice.base';
import { sysConfig } from '@/data/src/system-config';

interface FormData {
    key: string;
    label: string;
}

interface ComFormData {
    Account: string;
    Location: string;
    UUID: string;
}

// support导航栏
const getSubMenu = () => {
    const submain = {
        label: WordList.ProperAllTextToolbox,
        icon: 'el-icon-my-support'
    };
    const submenus = [{
        label: WordList.KnowledgeBase,
        name: 'knowledgeBase',
        path: 'https://knowledge.akuvox.com/'
    }, {
        label: WordList.Academy,
        name: 'academy',
        path: 'http://www.akuvox.com/SupportLearningCertification.aspx'
    }, {
        label: WordList.TechnicalSupport,
        name: 'technicalSupport',
        path: 'https://www.akuvox.com/Support/technical-support/'
    }, {
        label: WordList.ProperAllCommunity,
        name: 'community',
        path: 'http://community.akuvox.com/portal.php'
    }, {
        label: WordList.ProperAllTextCap,
        name: 'cap',
        path: 'http://www.akuvox.com/PartnersChannel.aspx'
    }];
    return {
        submain,
        submenus
    };
};

type OpenType = 'routerPush' | 'windowOpen' |'callBack';
interface MenuItem {
    name: string;
    label: string;
    icon: string;
    path: string | Function;
    type?: OpenType;
    children?: Array<{
        [key in string]: string;
    }>;
}
// 获取社区左侧导航栏，付费模式为pay by installer显示订阅入口
const getCommunityMenuList = (changeMode: string | number = '0', projectId = ''): MenuItem[] => {
    let menu: MenuItem[] = [{
        label: WordList.NavInHtmlMenuDashboard,
        name: `${installerBaseRouter}_${installerSubRouter.home}`,
        path: `/${installerBaseRouter}/${installerSubRouter.home}`,
        icon: 'el-icon-my-dashboard3'
    }, {
        label: ChangeWordList.NavPeopleAndDevice,
        name: `${communityBaseRouter}_${communitySubRouter.residentDevice}|${communityBaseRouter}_${communitySubRouter.devicedetail}`,
        path: `/${communityBaseRouter}/${communitySubRouter.residentDevice}?Build=community&type=user`,
        icon: 'el-icon-my-resident-device'
    }, {
        label: WordList.NavInHtmlMenuFirmware,
        name: `${communityBaseRouter}_${communitySubRouter.firmWare}`,
        path: `/${communityBaseRouter}/${communitySubRouter.firmWare}`,
        icon: 'el-icon-my-firmware'
    }, {
        label: WordList.Upgrade,
        name: `${communityBaseRouter}_${communitySubRouter.update}`,
        path: `/${communityBaseRouter}/${communitySubRouter.update}`,
        icon: 'el-icon-my-update'
    }, {
        label: WordList.NavInHtmlMenuMacLibrary,
        name: `${communityBaseRouter}_${communitySubRouter.library}`,
        path: `/${communityBaseRouter}/${communitySubRouter.library}`,
        icon: 'el-icon-my-mac-library'
    }, {
        label: WordList.NavInHtmlMenuPurchase,
        name: `${communityBaseRouter}_${communitySubRouter.subscriptions}`,
        path: `/${communityBaseRouter}/${communitySubRouter.subscriptions}`,
        icon: 'el-icon-my-subscription2'
    }, {
        label: WordList.NavInHtmlMenuOrder,
        name: `${installerBaseRouter}_${installerBatchRouter.batchPayments}`,
        path: `/${installerBaseRouter}/${installerBatchRouter.batchPayments}?type=${
            isOffice() ? 'office' : 'community'}&id=${projectId}`,
        icon: 'el-icon-my-payments'
    }];
    if (community.IsEnableSmartHome.value) {
        menu.push({
            label: WordList.HomeAutoMation,
            name: 'HomeAutomation',
            path: () => {
                communityApi.getSmartHomeUri((res: {Url: string}) => {
                    Notice.messageBox(
                        'confirm',
                        WordList.WantToHomeAutomation,
                        '',
                        'warning'
                    )(() => {
                        let unityUrl = '';
                        if (sysConfig.EnableSmartHome) {
                            unityUrl = `${process.env.VUE_APP_MIDDLE_URL}?joinType=login&akuvoxUrl=/${
                                installerBaseRouter}/${installerSubRouter.home}`;
                        }
                        window.open(sysConfig.EnableSmartHome ? `${
                            res.Url}&unity_url=${encodeURIComponent(unityUrl) as string}` : res.Url);
                    });
                });
            },
            icon: 'el-icon-my-zhinengjiaju_icon',
            type: 'callBack'
        });
    }
    // 6.5.2dis免密需求：dis登录ins隐藏payment、subscription
    if (Number(changeMode) === 1 || sessionStorage.getItem('token')) {
        menu = menu.filter((item) => {
            if (typeof item.path === 'string') {
                return item.path !== `/${communityBaseRouter}/${communitySubRouter.subscriptions}`
                && !item.path.includes(`/${installerBaseRouter}/${installerBatchRouter.batchPayments}`);
            }
            return item;
        });
    }
    return menu;
};

// 用户相关信息操作列表
const initHeader = () => {
    const isShowCouponDetails = ref(false);
    const isShowPasswordDialog = ref(false);
    const isShowTimeZoneDialog = ref(false);
    const isShowBillingDialog = ref(false);
    const isShowCustomerDialog = ref(false);
    const accountName = sessionStorage.getItem('userName') ? sessionStorage.getItem('userName') : localStorage.getItem('userName');
    const homeUrl = ref(`/${installerBaseRouter}/${installerSubRouter.home}`);
    const userOperaList = ref([{
        label: WordList.TabelChangePwTitle,
        key: 'password'
    }, {
        label: WordList.TabelCommunitiesTimeZone,
        key: 'timezone'
    }, {
        label: WordList.OrderBillingInformation,
        key: 'bill'
    }, {
        label: WordList.ProperAllTextCustomerService,
        key: 'customer'
    }, {
        label: WordList.NavInHtmlMenuOrder,
        key: 'payments'
    }]);
    if (community.ChargeMode.value !== 0) {
        userOperaList.value.pop();
    }
    return {
        isShowCouponDetails,
        isShowPasswordDialog,
        isShowTimeZoneDialog,
        isShowBillingDialog,
        isShowCustomerDialog,
        accountName,
        userOperaList,
        homeUrl
    };
};

const initMenu = (changeMode: string | number = '0') => {
    const menus = ref(getCommunityMenuList(changeMode));
    const InstallerType: Array<SelectItem> = [{
        key: '22',
        label: WordList.TabelUserInHtmlRoleInstall
    }, {
        key: '21',
        label: WordList.ProperAllTextProjectManager
    }];

    const CommunityList: Ref<Array<FormData>> = ref([]);
    const communityName: Ref<string> = ref('');
    const activeName = `${installerBaseRouter}_${installerSubRouter.home}`;
    communityName.value = ChangeWordList.ProjectListTitle;
    // 获取社区或者办公列表
    const requestUrl = isOffice() ? 'v3/web/office/project/getOfficeList' : 'v3/web/community/project/getAll';
    HttpRequest.get(requestUrl, {}, (res: {data: Array<ComFormData>}) => {
        CommunityList.value = [];
        communityName.value = ChangeWordList.ProjectListTitle;
        (res.data as any).row.forEach((item: any) => {
            CommunityList.value.push({ key: item.Account, label: item.Location });
            // 仪表盘不显示具体社区名
            if (router.currentRoute.value.name !== activeName
             && localStorage.getItem('Community') === item.Account) {
                communityName.value = item.Location;
            }
            // 保存当前办公UUID
            if (isOffice() && item.Account === localStorage.getItem('Community')) {
                office.officeId.value = item.UUID;
            }
        });
    });
    return {
        menus,
        InstallerType,
        communityName,
        CommunityList,
        activeName
    };
};

interface ControlType {
    role: boolean;
    community: boolean;
    account: boolean;
}
const controlPop = () => {
    const controlPopVal: Ref< ControlType > = ref({
        role: false,
        community: false,
        account: false
    });
    const hideAllPop = () => {
        controlPopVal.value.role = false;
        controlPopVal.value.account = false;
        controlPopVal.value.community = false;
    };
    const hideOtherPop = (event: boolean, type: keyof ControlType) => {
        controlPopVal.value[type] = event;
        if (event) {
            Object.keys(controlPopVal.value).forEach((key) => {
                const itemKey = key as keyof ControlType;
                if (itemKey !== type) {
                    controlPopVal.value[itemKey] = false;
                }
            });
        }
    };
    return {
        hideAllPop,
        hideOtherPop,
        controlPopVal
    };
};

export default null;
export {
    FormData,
    ComFormData,
    initHeader,
    initMenu,
    getSubMenu,
    getCommunityMenuList,
    controlPop
};
