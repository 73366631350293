import { loginApi } from '@/api';
import eventBus from '@/util/use-event-bus/global-event-bus';
import { ref } from 'vue';
// @ts-ignore
const getTokenInterval = ref<NodeJS.Timer|null>(null);
export default function useGetTokenStatus() {
    if (getTokenInterval.value) {
        clearInterval(getTokenInterval.value);
        getTokenInterval.value = null;
    }
    getTokenInterval.value = setInterval(() => {
        loginApi.getTokenStatus({
            account: (sessionStorage.getItem('account') ? sessionStorage.getItem('account') : localStorage.getItem('account'))!
        });
    }, 60000);
    // 监听退出操作
    eventBus.once('logOut', () => {
        clearInterval(getTokenInterval.value!);
        getTokenInterval.value = null;
    });
}
