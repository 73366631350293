import { ListHeaderItem } from '@/components/common/list';

const secNavs = [{
    label: WordList.ProperAllTextNormal,
    key: 'Normal'
}, {
    label: WordList.ProperAllTextUsed,
    key: 'Used'
}, {
    label: WordList.ProperAllTextExpired,
    key: 'Expired'
}];

const headers: Array<ListHeaderItem> = [{
    label: WordList.ProperAllTextCouponNumber,
    name: 'Number'
}, {
    label: WordList.ProperAllTextCouponType,
    name: 'Type'
}, {
    label: WordList.ProperAllTextCouponAmount,
    name: 'Amount'
}, {
    label: `${WordList.ProperAllTextEffectiveTime}/${WordList.TabelExpretimeBoxSpecificTime}`,
    name: 'Time'
}];

const FeeType = [
    WordList.ProperAllTextAllFee,
    WordList.ProperAllTextActivationFee,
    WordList.ProperAllTextRenewMonthlyFee,
    WordList.ProperAllTextAdditionalFee
];

type Status = 'Normal' | 'Used' | 'Expired';

interface CouponInfo {
    Number: string;
    Type: number;
    UsedCount: number;
    TotalCount: number;
    StartTime: string;
    StopTime: string;
    TotalBalance: string;
}

type CouponResItem = {
    [key in Status]: Array<CouponInfo>;
};

export default null;
export {
    secNavs,
    headers,
    FeeType,
    Status,
    CouponInfo,
    CouponResItem
};