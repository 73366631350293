
import {
    defineComponent, Ref, watch, ref, reactive
} from 'vue';
import textPopover from '@/components/common/text-popover/index.vue';
import '@/assets/less/index.less';
import couponDetails from '@/components/view/common/dialog/coupon-details';
import passwordDialog from '@/components/view/common/dialog/password-change';
import billingDialog from '@/components/view/common/dialog/billing-installer';
import setTimeZoneDialog from '@/components/view/common/dialog/time-change';
import customerDialog from '@/components/view/common/dialog/customer';
import { horizontal, vertical, subvertical } from '@/components/common/nav/index';
import selectList from '@/components/common/select-list';
import goBackLogin from '@/router/back-login';
import setLang from '@/methods/setLang';
import router, { installerBatchRouter } from '@/router';
import HttpRequest from '@/util/axios.config';
import {
    installerBaseRouter,
    communitySubRouter,
    installerSubRouter,
    communityBaseRouter
} from '@/router/data';

import {
    community, account, distributor,
    systemConfig, communityType
} from '@/data';
import { isCommunity, isOffice, isInstaller } from '@/methods/role';
import changeTextByScene, { ChangeWordList } from '@/methods/setText';
import useGetTokenStatus from '@/util/use-get-token-status';
import noticeBase from '@/util/notice.base';
import { getInitData, GetInitData } from '@/api/common/installer';
import accountSettingDialog from '@/components/view/common/dialog/account-setting';
import {
    initHeader, initMenu, getSubMenu, getCommunityMenuList,
    controlPop
} from './util';

export default defineComponent({
    components: {
        horizontal,
        vertical,
        textPopover,
        selectList,
        passwordDialog,
        billingDialog,
        setTimeZoneDialog,
        customerDialog,
        subvertical,
        couponDetails,
        accountSettingDialog
    },

    setup() {
        useGetTokenStatus();

        const {
            hideAllPop,
            hideOtherPop,
            controlPopVal
        } = controlPop();
        changeTextByScene();
        if (!isOffice()) {
            systemConfig.getSysConfig((res) => {
                systemConfig.sysConfig.EnableSmartHome = res.EnableSmartHome;
            });
        }
        setLang();
        const {
            isShowCouponDetails,
            isShowPasswordDialog,
            isShowTimeZoneDialog,
            isShowBillingDialog,
            isShowCustomerDialog,
            accountName,
            userOperaList,
            homeUrl
        } = initHeader();

        const {
            menus,
            InstallerType,
            communityName,
            CommunityList,
            activeName
        } = initMenu(community.ChargeMode.value);
        const {
            submain,
            submenus
        } = getSubMenu();

        // 控制单住户、社区切换显示
        const isSpecial = ref(false);
        const ShowComList = () => {
            if (isInstaller()) {
                HttpRequest.get('isspecial', {}, () => {
                    isSpecial.value = false;
                }, [() => {
                    isSpecial.value = true;
                }, false]);
            } else if (isCommunity() || isOffice()) {
                isSpecial.value = true;
            } else {
                isSpecial.value = false;
            }
        };
        ShowComList();

        // 全局相关配置
        const projectId = ref('');
        const getConfig = () => {
            community.getCommunityDetail((res: {
                data: communityType.CommunityDetail;
            }) => {
                community.IsEnableLandline.value = res.data.EnableLandline;
                community.enableCommunitySmartHome.value = res.data.EnableSmartHome;
                community.isNew.value = true;
                community.thirdPartyPlan.value = res.data.ThirdPartyDevices === 1;
                projectId.value = res.data.AccountID;
                menus.value = getCommunityMenuList(community.ChargeMode.value, projectId.value);
                if (res.data.SmartHomeUUID !== undefined) {
                    community.smartHomeUUID.value = res.data.SmartHomeUUID;
                }
            });
        };
        getConfig();

        const changeGrade = (newAccount: string | null) => {
            account.changePCIdentity(newAccount, (res: {
                Token: string;
                Grade: string;
            }) => {
                if (sessionStorage.getItem('token')) {
                    sessionStorage.setItem('token', res.Token);
                } else {
                    localStorage.setItem('token', res.Token);
                }
                localStorage.setItem('grade', res.Grade);
                if (res.Grade === '22') {
                    router.push(`/${installerBaseRouter}/${installerSubRouter.user}`);
                } else {
                    getConfig();
                    router.push(`/${communityBaseRouter}/${communitySubRouter.residentDevice}`);
                }
            });
        };

        // 切换role，从社区切换单住户重新请求token，从单住户切换社区不重新请求
        const changeInstaller = (key: string) => {
            hideAllPop();
            localStorage.setItem('grade', key);
            if (key === '22') {
                changeGrade(localStorage.getItem('peraccount'));
                localStorage.setItem('InstallerLoginType', '0');
            } else {
                // communityName.value = ChangeWordList.ProjectListTitle;
                router.push(`/${installerBaseRouter}/${installerSubRouter.home}`);
                localStorage.setItem('InstallerLoginType', '1');
            }
        };

        // 获取付费模式，控制代金券显示
        distributor.getDisChargeMode((res: {ChargeMode: string}) => {
            community.ChargeMode.value = Number(res.ChargeMode);
            menus.value = getCommunityMenuList(community.ChargeMode.value, projectId.value);
            // pay by dis关闭时显示代金券选项
            if (res.ChargeMode !== community.PayByDis) {
                const couponItem = {
                    label: WordList.ProperAllTextCouponDetails,
                    key: 'coupon'
                };
                // 判断是否存在该选项，避免重复出现（新框架通过hidden控制）
                if (userOperaList.value.findIndex((item) => item.key === 'coupon') === -1) {
                    userOperaList.value.unshift(couponItem);
                }
            }
        });

        // 获取系统配置，控制support导航栏显示
        const isShowToolBox = ref(1);
        const downFileList: Ref<Array<string>> = ref([]);
        systemConfig.getToolMenu((data: Record<string, any>) => {
            isShowToolBox.value = data.Show;
            data.List.forEach((item: string) => {
                downFileList.value.push(item);
            });
        });

        // 切换社区
        const changeCommunity = (key: string) => {
            hideAllPop();
            localStorage.setItem('Community', key);
            CommunityList.value.forEach((item) => {
                if (item.key === key) { communityName.value = item.label; }
            });
            changeGrade(key);
        };

        // 监听路由，仪表盘页面不显示左侧导航栏
        const activeIndex = ref(router.currentRoute.value.name);
        watch(router.currentRoute, () => {
            activeIndex.value = router.currentRoute.value.name;
        });

        watch(community.IsEnableSmartHome, () => {
            menus.value = getCommunityMenuList(community.ChargeMode.value, projectId.value);
        }, {
            immediate: true
        });

        const chooseOpera = (key: string) => {
            hideAllPop();
            switch (key) {
                case 'coupon':
                    isShowCouponDetails.value = true;
                    break;
                case 'password':
                    isShowPasswordDialog.value = true;
                    break;
                case 'timezone':
                    isShowTimeZoneDialog.value = true;
                    break;
                case 'bill':
                    isShowBillingDialog.value = true;
                    break;
                case 'customer':
                    isShowCustomerDialog.value = true;
                    break;
                case 'payments': {
                    localStorage.setItem('Community', '');
                    const type = localStorage.getItem('grade') === account.communityRole.toString() ? 'community' : 'office';
                    router.push(`/${installerBaseRouter}/${installerBatchRouter.batchPayments}?type=${type}`);
                    break;
                }
                case 'accountSetting':
                    isShowAccountSettingDialog.value = true;
                    break;
                default:
                    break;
            }
        };

        /**
         * 6.5.2dis免密登录需求
         * ins控制免密登录是否开启
         */
        // 控制是否显示account setting弹窗
        const isShowAccountSettingDialog = ref(false);
        // 获取ins是否开启免密登录
        const pwFreeConfig = reactive({
            IsPwdFreeLogin: 0
        });
        // 判断是否为dis登录
        const isDisLogin = ref(false);
        function getPwFreeConfig() {
            if (sessionStorage.getItem('token')) {
                isDisLogin.value = true;
            } else {
                getInitData({}, (res: GetInitData) => {
                    // dis第一次打开开关后,ins第一次登录弹窗提示
                    if (res.IsPwdFreeLoginNotice === 1) {
                        noticeBase.messageBox(
                            'alert',
                            WordList.FirstAccessAccountTips,
                            WordList.Notice,
                            'warning'
                        )(() => false);
                    }
                    // dis开启免密登录，ins新增选项Account Setting
                    if (res.IsShowPermissionGranted === 1) {
                        // 判断是否存在该选项，避免重复出现（新框架通过hidden控制）
                        if (userOperaList.value.findIndex((item) => item.key === 'accountSetting') === -1) {
                            userOperaList.value.push({
                                label: WordList.AccountSetting,
                                key: 'accountSetting'
                            });
                        }
                    }
                    pwFreeConfig.IsPwdFreeLogin = res.IsPwdFreeLogin;
                });
            }
        }
        getPwFreeConfig();

        return {
            menus,
            accountName,
            communityName,
            isShowCouponDetails,
            isShowPasswordDialog,
            isShowTimeZoneDialog,
            isShowBillingDialog,
            isShowCustomerDialog,
            InstallerType,
            goBackLogin,
            changeInstaller,
            chooseOpera,
            activeIndex,
            activeName,
            CommunityList,
            changeCommunity,
            homeUrl,
            isSpecial,
            downFileList,
            isShowToolBox,
            userOperaList,
            account,
            submain,
            submenus,
            hideOtherPop,
            controlPopVal,
            chargeMode: community.ChargeMode,
            isShowAccountSettingDialog,
            pwFreeConfig,
            getPwFreeConfig,
            isDisLogin
        };
    }
});

