
import {
    defineComponent, PropType, ref, Ref
} from 'vue';
import { account as accountRequest } from '@/data/';
import HttpRequest from '@/util/axios.config';
import { user } from '@/methods/rule';

interface FormData {
    Phone: string;
    Email: string;
}
type UserType = 'distributor' | 'installer';
export default defineComponent({
    props: {
        userType: {
            type: String as PropType<UserType>
        }
    },
    setup(props, { emit }) {
        const form: Ref<any> = ref(null);
        const rules = {
            Phone: [
                { required: true, message: WordList.RuleCommonLabelEmpty2.format(WordList.TabelPersonUserInHtmlPhone), trigger: 'blur' }
            ],
            Email: [
                {
                    required: true,
                    message: WordList.RuleEmailEmpty,
                    trigger: 'blur'
                }, {
                    validator: user.checkEmail,
                    trigger: 'change'
                }
            ]
        };

        const getFormData = () => {
            const formData: Ref<FormData> = ref({
                Phone: '',
                Email: ''
            });
            switch (props.userType) {
                case 'installer':
                    accountRequest.getCustomerServerForInstaller((data: FormData) => { formData.value = data; });
                    break;
                default:
                    rules.Phone = [];
                    rules.Email = [];
                    break;
            }
            return formData;
        };
        const formData = getFormData();

        const submit = () => {
            const urls = 'v3/web/common/customerServer/save';
            form.value.validate((valid: boolean) => {
                if (!valid) return false;
                HttpRequest.post(urls, formData.value, () => {
                    emit('close');
                    emit('success');
                });
                return true;
            });
        };

        return {
            formData,
            submit,
            form,
            rules
        };
    }
});
