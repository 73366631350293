import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createBlock as _createBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "width70per margin-0-auto" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cus_option = _resolveComponent("cus-option")
  const _component_cus_select = _resolveComponent("cus-select")
  const _component_el_form_item = _resolveComponent("el-form-item")
  const _component_el_form = _resolveComponent("el-form")
  const _component_dialog_shell = _resolveComponent("dialog-shell")

  return (_openBlock(), _createBlock(_component_dialog_shell, {
    title: _ctx.$wordList.TabelCommunitiesTimeZone,
    onClose: _ctx.close,
    onSubmit: _ctx.submit
  }, {
    default: _withCtx(() => [
      _createVNode("div", _hoisted_1, [
        _createVNode(_component_el_form, { "label-position": _ctx.$formLabelPosition }, {
          default: _withCtx(() => [
            _createVNode(_component_el_form_item, {
              label: _ctx.$wordList.TabelCommunitiesTimeZone
            }, {
              default: _withCtx(() => [
                _createVNode(_component_cus_select, {
                  width: "100%",
                  filter: "",
                  modelValue: _ctx.timeOption,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.timeOption = $event))
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createBlock(_Fragment, null, _renderList(_ctx.timeZone.timeZoneOptions, (item, index) => {
                      return (_openBlock(), _createBlock(_component_cus_option, {
                        key: index,
                        value: item.value,
                        label: item.label
                      }, null, 8, ["value", "label"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            }, 8, ["label"])
          ]),
          _: 1
        }, 8, ["label-position"])
      ])
    ]),
    _: 1
  }, 8, ["title", "onClose", "onSubmit"]))
}